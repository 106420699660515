(function () {
  "use strict";

  $(document).ready(function () {

    $('.project-slider').slick({
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
        breakpoint: 900,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
        }
        },
        {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        }
        }
    ]
});

   $('.slider-inner-section').slick({
    mobileFirst: true,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
        breakpoint: 655,
        settings: 'unslick',
        },
    ]
});

    $('.slider-partners').slick({
    dots: true,
    arrows: false,
    infinite: true,
    autoplay:true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
        breakpoint: 900,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        }
        },
         {
        breakpoint: 656,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        }
        },
        {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
        }
    ]
});

   $('.slider-icon').slick({
    mobileFirst: true,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
        breakpoint: 655,
        settings: 'unslick',
        },
    ]
});

   $('.slider-col-img').slick({
    mobileFirst: true,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
        breakpoint: 655,
        settings: 'unslick',
        },
    ]
});



  }); // close document get ready
})(jQuery);