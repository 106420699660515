(function () {
  "use strict";

  $(document).ready(function () {

    // Mobile menu
    $(".menu-toggle").on("click", function () {
      $(".menu-mobile").addClass("menu-mobile-open");
    });
    $(".menu-mobile-close").on("click", function () {
      $(".menu-mobile").removeClass("menu-mobile-open");
    });

    // Accordion mobile menu
    $(".menu_mobile-nav > li").on("click", function () {
      $(this).children(".sub-menu").slideToggle();
    });

     // Menu fixed
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      var header = document.getElementById("header");
      var shouldAddClass = window.scrollY > 48;

      header.classList.toggle("scrolled", shouldAddClass);
    }




  }); // close document get ready
})(jQuery);